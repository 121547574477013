import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ChallengeList from './challenge-list';

const AdminChallengeSelection = (props) => {
  const {
    eventId,
    secretKey,
    challengeId,
    fetchTournamentData,
    eventStatus,
    setAlertMessage,
    setButtonChoices,
  } = props;
  const [challengeList, setChallengeList] = useState([]);

  // retrieve all of the challenges on page load
  useEffect(() => {
    axios({
      method: 'GET',
      // use the url to check if we should return experimental challenges
      url: `${process.env.ABAMATH_API_URL}/code-championship/projects/challenges`,
    }).then((response) => {
      setChallengeList(response.data);
    });
  }, []);

  // update current challenge in database based on selection
  const makeRequestToUpdateEventChallengeId = (newChallengeId) => {
    axios({
      method: 'PUT',
      // use the url to check if we should return experimental challenges
      params: { eventId, secretKey },
      data: { challengeId: newChallengeId },
      url: `${process.env.ABAMATH_API_URL}/code-championship/event/challenge-id`,
    }).then(fetchTournamentData);
  };

  const updateEventChallengeId = (newChallengeId) => {
    if (!challengeId) {
      makeRequestToUpdateEventChallengeId(newChallengeId);
    } else if (eventStatus === 'Finished') {
      setAlertMessage('Cannot change the challenge after the tournament is finished.');
    } else {
      setAlertMessage('If bots have already been created, changing the challenge may have some weird consequences!');
      setButtonChoices([{
        text: 'Undo! Take me back!',
      }, {
        onClick: () => makeRequestToUpdateEventChallengeId(newChallengeId),
        className: 'stripe-button secondary',
        text: 'I am sure! I know what I am doing.',
      }]);
    }
  };

  const difficultyLevels = Object.keys(challengeList
    .reduce((aggregate, challenge) => ({
      ...aggregate,
      [Math.floor(challenge.difficulty)]: true,
    }), {}))
    .map(Number).sort().map(String);

  return (
    <section style={{ position: 'relative' }}>
      {/* challenge container is full width before a challenge is selected
      then goes to 200px to give column on the side */}
      <section className="challenge-container">
        {/* Each difficulty gets its own Challenge List */}
        {difficultyLevels.map((level) => (
          <ChallengeList
            key={level}
            challenges={challengeList}
            difficulty={level}
            challengeId={challengeId}
            setChallengeId={updateEventChallengeId}
            isAdmin
          />
        ))}
      </section>
    </section>
  );
};

AdminChallengeSelection.propTypes = {
  eventId: PropTypes.string.isRequired,
  secretKey: PropTypes.string.isRequired,
  challengeId: PropTypes.string,
  fetchTournamentData: PropTypes.func.isRequired,
  setAlertMessage: PropTypes.func.isRequired,
  setButtonChoices: PropTypes.func.isRequired,
  eventStatus: PropTypes.string.isRequired,
};

AdminChallengeSelection.defaultProps = {
  challengeId: null,
};

export default AdminChallengeSelection;
