const playerSort = (playerOne, playerTwo) => {
  const playerOneName = (playerOne.display_name || playerOne.name).toUpperCase();
  const playerTwoName = (playerTwo.display_name || playerTwo.name).toUpperCase();
  if (playerOneName < playerTwoName) {
    return -1;
  }
  if (playerOneName > playerTwoName) {
    return 1;
  }
  return 0;
};

export default playerSort;
