import React from 'react';
import PropTypes from 'prop-types';

const SeedStandings = ({ seeds }) => (
  <>
    <p style={{ margin: '0' }}>Two points for a win. One point for a tie.</p>
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr' }}>
      {seeds.map((pool) => (
        <div key={pool[0].pool_id} style={{ marginTop: '25px' }}>
          {pool.map((player) => (
            <div key={player.id} style={{ display: 'grid', gridTemplateColumns: '30px auto 10px' }}>
              <div style={{
                color: 'white', background: '#011454', fontSize: '30px', paddingLeft: '5px',
              }}
              >
                {player.seed}
              </div>
              <div style={{ border: '1px solid #011454' }}>
                <div style={{ paddingLeft: '5px' }}>
                  {player.display_name}
                </div>
                <div style={{ paddingLeft: '5px' }}>
                  {`${player.wins} - ${player.losses} - ${player.ties} | ${player.point_count}`}
                </div>
              </div>
              <div />
            </div>
          ))}
        </div>
      ))}
    </div>
  </>
);

SeedStandings.propTypes = {
  seeds: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({}).isRequired,
    ).isRequired,
  ).isRequired,
};

export default SeedStandings;
