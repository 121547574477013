import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

const AdminPlayerRow = (props) => {
  const {
    eventId,
    secretKey,
    fetchTournamentData,
    eventStatus,
    setAlertMessage,
    setButtonChoices,
  } = props;
  const [player, setPlayer] = useState(props.player);
  const updatePlayer = (propertyName, newValue) => {
    setPlayer({
      ...player,
      [propertyName]: newValue,
    });
  };

  const savePlayer = (playerToSave) => {
    if (eventStatus === 'Registration' || playerToSave.bot_number) {
      axios({
        headers: { 'Access-Control-Allow-Origin': '*' },
        method: 'PUT',
        url: `${process.env.ABAMATH_API_URL}/code-championship/registrant/admin`,
        params: { eventId, secretKey },
        data: { player: playerToSave },
      }).then(() => {
        fetchTournamentData();
      }).catch(() => {
        setAlertMessage('Save was not successful. This is most likely because that bot number is not in the database.');
      });
    } else {
      setAlertMessage('A player must have a Bot Number in Pool Play and Bracket Play');
    }
  };

  const makeRequestToDeactivatePlayer = () => {
    axios({
      headers: { 'Access-Control-Allow-Origin': '*' },
      method: 'PUT',
      url: `${process.env.ABAMATH_API_URL}/code-championship/registrant/admin`,
      params: { eventId, secretKey },
      data: {
        player: {
          ...player,
          isActive: false,
        },
      },
    }).then(() => {
      fetchTournamentData();
    }).catch(() => {
      setAlertMessage('Removing the player was not successful. If this happens a lot, send us an email!');
    });
  };

  const deactivatePlayer = () => {
    if (eventStatus === 'Registration') {
      makeRequestToDeactivatePlayer();
    } else if (eventStatus === 'Finished') {
      setAlertMessage('Cannot deactivate a player after the tournament is finished.');
    } else {
      setAlertMessage(`Deactivating a player during ${eventStatus} may have some weird consequences! (If games have already been played, scoring could be altered.)`);
      setButtonChoices([{
        text: 'Undo! Take me back!',
      }, {
        onClick: makeRequestToDeactivatePlayer,
        className: 'stripe-button secondary',
        text: 'I am sure! Deactivate the player.',
      }]);
    }
  };

  const rejectSuggestedBotNumber = (rejectedBotNumber) => {
    axios({
      headers: { 'Access-Control-Allow-Origin': '*' },
      method: 'PUT',
      url: `${process.env.ABAMATH_API_URL}/code-championship/registrant/reject-suggested-bot-number`,
      params: { eventId, secretKey },
      data: {
        player,
        rejectedBotNumber,
      },
    }).then(() => {
      fetchTournamentData();
    }).catch(() => {
      setAlertMessage('Removing the player was not successful. If this happens a lot, send us an email!');
    });
  };

  const acceptSuggestedBotNumber = (acceptedBotNumber) => {
    const playerToSave = { ...player, bot_number: acceptedBotNumber };
    setPlayer(playerToSave);
    savePlayer(playerToSave);
  };

  const databaseBotNumber = Number(props.player.bot_number);
  const suggestedBotNumber = Number(props.player.suggested_bot_number);
  const typedBotNumber = Number(player.bot_number);
  const displayNameDifference = props.player.display_name !== player.display_name;
  const botNumberDifference = databaseBotNumber !== Number(player.bot_number);
  const suggestedBotDifference = suggestedBotNumber > 0 && suggestedBotNumber !== typedBotNumber;
  const databaseDifference = displayNameDifference || botNumberDifference;

  return (
    <>
      <tr>
        <td>
          <input
            value={player.display_name}
            onChange={(event) => updatePlayer('display_name', event.target.value)}
          />
        </td>
        <td>
          <input
            value={player.bot_number || ''}
            onChange={(event) => updatePlayer('bot_number', event.target.value)}
            style={player.bot_number ? {} : { border: '2px solid #B30300' }}
          />
        </td>
        <td>
          <button className="small-button" type="button" onClick={deactivatePlayer}>
            Remove
          </button>
        </td>
        {databaseDifference ? (
          <>
            {player.display_name === '' ? (
              <td>
                Players without names cannot be saved.
                Remove this player, or refresh to get their previous name.
                <button className="small-button" type="button" onClick={() => setPlayer(props.player)}>
                  Refresh
                </button>
              </td>
            ) : (
              <td>
                This player has changed.
                <button className="small-button" type="button" onClick={() => savePlayer(player)}>
                  Save My Changes
                </button>
                <button className="small-button" type="button" onClick={() => setPlayer(props.player)}>
                  Accept Incoming Changes
                </button>
              </td>
            )}
          </>
        ) : (
          <>
            {suggestedBotDifference && (
              <td>
                {`Bot number ${suggestedBotNumber} has been suggested for this player.`}
                <button className="small-button" type="button" onClick={() => acceptSuggestedBotNumber(suggestedBotNumber)}>
                  Accept
                </button>
                <button className="small-button" type="button" onClick={() => rejectSuggestedBotNumber(suggestedBotNumber)}>
                  Reject
                </button>
              </td>
            )}
          </>
        )}
      </tr>
    </>
  );
};

AdminPlayerRow.propTypes = {
  player: PropTypes.shape({
    id: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
    bot_number: PropTypes.string,
    suggested_bot_number: PropTypes.string,
  }).isRequired,
  eventId: PropTypes.string.isRequired,
  secretKey: PropTypes.string.isRequired,
  fetchTournamentData: PropTypes.func.isRequired,
  setAlertMessage: PropTypes.func.isRequired,
  setButtonChoices: PropTypes.func.isRequired,
  eventStatus: PropTypes.string.isRequired,
};

export default AdminPlayerRow;
