import { useState } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { navigate } from 'gatsby';

const tournamentArrayToObject = (tournamentArray) => tournamentArray
  .reduce((aggregate, {
    eventId, tournamentUrl, secretKey, playerSecretKey, title,
  }) => (
    {
      ...aggregate,
      [eventId]: {
        eventId,
        tournamentUrl: tournamentUrl || `/tournament/home/?eventId=${eventId}`,
        secretKey,
        playerSecretKey,
        title,
      },
    }
  ), {});

const useTournamentAuth = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [isChecking, setIsChecking] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  let isSubscribed = true;
  let nextEventId = '';

  const signOut = (eventIdToSignOut) => {
    if (isSubscribed) {
      const updatedTournamentAccessList = Object.values(cookies.tournamentAccessList || {})
        .filter((tournament) => tournament.eventId !== eventIdToSignOut);
      setCookie(
        'tournamentAccessList',
        tournamentArrayToObject(updatedTournamentAccessList),
        { path: '/', sameSite: 'strict' },
      );
      // sign in to another tournament if there is another on the list
      // log out completely if there isn't another tournament
      if (cookies.eventId === eventIdToSignOut) {
        removeCookie('eventId', { path: '/', sameSite: 'strict' });
        removeCookie('secretKey', { path: '/', sameSite: 'strict' });
        removeCookie('playerSecretKey', { path: '/', sameSite: 'strict' });
        setIsSignedIn(false);
        if (updatedTournamentAccessList.length > 0) {
          setCookie('eventId', updatedTournamentAccessList[0].eventId, { path: '/', sameSite: 'strict' });
          setCookie('playerSecretKey', updatedTournamentAccessList[0].playerSecretKey, { path: '/', sameSite: 'strict' });
          if (updatedTournamentAccessList[0].secretKey) {
            setCookie('secretKey', updatedTournamentAccessList[0].secretKey, { path: '/', sameSite: 'strict' });
          } else {
            removeCookie('secretKey', { path: '/', sameSite: 'strict' });
          }
          navigate(updatedTournamentAccessList[0].tournamentUrl);
        } else {
          navigate('/');
        }
      }
    }
  };

  const accessTournament = ({ eventId, tournamentAccessList }) => {
    const existingEvent = tournamentAccessList[eventId];
    const secretKey = existingEvent?.secretKey || null;
    const playerSecretKey = existingEvent?.playerSecretKey || null;
    if (secretKey || playerSecretKey) {
      setIsChecking(true);
      axios({
        headers: { 'Access-Control-Allow-Origin': '*' },
        method: 'POST',
        url: `${process.env.ABAMATH_API_URL}/code-championship/event/access`,
        params: { eventId, secretKey, playerSecretKey },
      }).then((response) => {
        if (isSubscribed) {
          setCookie('eventId', eventId, { path: '/', sameSite: 'strict' });
          setCookie('playerSecretKey', playerSecretKey, { path: '/', sameSite: 'strict' });
          if (secretKey) {
            setCookie('secretKey', secretKey, { path: '/', sameSite: 'strict' });
          } else {
            removeCookie('secretKey', { path: '/', sameSite: 'strict' });
          }
          setIsSignedIn(true);
          const tournamentUrl = `/tournament/home/?eventId=${eventId}`;
          const updatedTournamentAccessList = {
            ...tournamentAccessList,
            [eventId]: {
              eventId,
              secretKey,
              playerSecretKey: response.data.playerSecretKey,
              tournamentUrl,
              title: response.data.title,
            },
          };
          setCookie('tournamentAccessList', updatedTournamentAccessList, { path: '/', sameSite: 'strict' });
          navigate(tournamentUrl, { replace: true });
        }
      }).catch(() => {
        if (isSubscribed) setIsSignedIn(false);
      }).finally(() => {
        if (isSubscribed) setIsChecking(false);
      });
    } else {
      signOut();
    }

    return () => { isSubscribed = false; };
  };

  const signIn = ({
    eventId, secretKey, playerSecretKey, tournamentArray = [],
  }) => {
    if (isSubscribed) {
      // if it contains more than just an eventId, update the data first
      const existingEvent = (cookies?.tournamentAccessList || {})[eventId];
      const updatedTournamentAccessList = {
        ...tournamentArrayToObject(tournamentArray),
        ...(cookies?.tournamentAccessList || {}),
        ...(eventId ? {
          [eventId]: {
            ...existingEvent,
            secretKey: secretKey || existingEvent?.secretKey,
            playerSecretKey: playerSecretKey || existingEvent?.playerSecretKey,
          },
        } : {}),
      };

      // once secret keys (etc.) have been added to cookies, attempt to log in to the event
      const firstNewTournamentId = tournamentArray[0]?.eventId;
      const firstOldTournamentId = Object.keys(updatedTournamentAccessList)[0];
      const selectedEventId = eventId || firstNewTournamentId
        || cookies.eventId || firstOldTournamentId;
      if (selectedEventId && selectedEventId !== nextEventId) {
        nextEventId = selectedEventId;
        accessTournament({
          eventId: selectedEventId,
          tournamentAccessList: updatedTournamentAccessList,
        });
      }
    }
    return () => { isSubscribed = false; };
  };

  return {
    signOut, signIn, isChecking, isSignedIn,
  };
};

export default useTournamentAuth;
