// @flow
import React from 'react';
import styled from '@emotion/styled';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import PlayerItem from './player-item';

export const getBackgroundColor = (
  isDraggingOver,
  isDraggingFrom,
) => {
  if (isDraggingOver) {
    return 'grey';
  }
  if (isDraggingFrom) {
    return 'lightgrey';
  }
  return 'lightblue';
};

const Wrapper = styled.div`
  background-color: ${(props) =>
    getBackgroundColor(props.isDraggingOver, props.isDraggingFrom)};
  display: flex;
  flex-direction: column;
  padding: 8px;
  border: 8px;
  padding-bottom: 0;
  transition: background-color 0.2s ease, opacity 0.1s ease;
  user-select: none;
`;

const DropZone = styled.div`
  /* stop the list collapsing when empty */
  min-height: ${({ listId }) => listId === 'Not In Pool Yet' ? '200px' : '50px'};
  /*
    not relying on the items for a margin-bottom
    as it will collapse when the list is empty
  */
  padding-bottom: 8px;
`;

/* stylelint-disable block-no-empty */
const Container = styled.div``;
/* stylelint-enable */

const InnerPlayerList = React.memo(function InnerPlayerList(
  props,
) {
  return (props.players || []).map((player, index) => (
    <Draggable key={player.id} draggableId={player.id} index={index}>
      {(
        dragProvided,
        dragSnapshot,
      ) => (
        <PlayerItem
          key={player.id}
          player={player}
          isDragging={dragSnapshot.isDragging}
          isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
          provided={dragProvided}
        />
      )}
    </Draggable>
  ));
});

function InnerList(props) {
  const { players, dropProvided, listId } = props;
  return (
    <Container>
      <DropZone ref={dropProvided.innerRef} listId={listId}>
        <InnerPlayerList players={players} />
        {dropProvided.placeholder}
      </DropZone>
    </Container>
  );
}

const PlayerList = ({ listId, players }) => (
  <Droppable droppableId={listId}>
    {(dropProvided, dropSnapshot) => (
      <Wrapper
        isDraggingOver={dropSnapshot.isDraggingOver}
        isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
        {...dropProvided.droppableProps}
      >
        <InnerList
          players={players}
          dropProvided={dropProvided}
          listId={listId}
        />
      </Wrapper>
    )}
  </Droppable>
);

PlayerList.propTypes = {
  listId: PropTypes.string.isRequired,
  players: PropTypes.arrayOf(
    PropTypes.shape({}).isRequired,
  ).isRequired,
};

export default PlayerList;
