import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import axios from 'axios';
import { DragDropContext } from 'react-beautiful-dnd';
import { reorderPlayerMap } from './dnd/reorder';
import Column from './column';

const Container = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
`;

const TournamentPoolCreation = ({
  eventId,
  secretKey,
  eventStatus,
  fetchTournamentData,
  setAlertMessage,
  setButtonChoices,
  ...props
}) => {
  const [columns, setColumns] = useState(props.columns);
  const [ordered, setOrdered] = useState(props.ordered);
  useEffect(() => {
    setColumns(props.columns);
    setOrdered(props.ordered);
  }, [props.columns, props.ordered]);
  const changePool = (result) => {
    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    // did not change pools - can bail early
    if (source.droppableId === destination.droppableId) return;

    // change state order to prevent jumping
    // (it takes a second to make the database round trip, update the order first)
    setColumns(reorderPlayerMap({
      playerMap: columns,
      source,
      destination,
    }).playerMap);

    axios({
      headers: { 'Access-Control-Allow-Origin': '*' },
      method: 'PUT',
      url: `${process.env.ABAMATH_API_URL}/code-championship/registrant/pool`,
      params: { eventId, secretKey },
      data: {
        registrantId: result.draggableId,
        poolName: destination.droppableId,
      },
    }).then(() => {
      fetchTournamentData();
    }).catch(() => {
      setAlertMessage('This update failed. We aren\'t totally sure why. If this happens a lot, send us an email!');
    });
  };

  const onDragEnd = (result) => {
    if (eventStatus === 'Registration') {
      changePool(result);
    } else if (eventStatus === 'Finished') {
      setAlertMessage('Cannot change pools after a tournament is finished.');
    } else {
      setAlertMessage(`Changing a player's pool during ${eventStatus} may have some weird consequences! (If games have already been played, scoring could be altered.)`);
      setButtonChoices([{
        text: 'Undo! Take me back!',
      }, {
        onClick: () => changePool(result),
        className: 'stripe-button secondary',
        text: 'I am sure! Change this player\'s pool.',
      }]);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Container>
        {ordered[0] && [ordered[0]].map((key) => (
          <Column
            key={key}
            title={key}
            players={columns[key]}
          />
        ))}
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridAutoRows: 'max-content' }}>
          {ordered[0] && ordered.slice(1) && ordered.slice(1).map((key) => (
            <Column
              key={key}
              title={key}
              players={columns[key]}
            />
          ))}
        </div>
      </Container>
    </DragDropContext>
  );
};

TournamentPoolCreation.propTypes = {
  columns: PropTypes.shape({}).isRequired,
  ordered: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  eventId: PropTypes.string.isRequired,
  secretKey: PropTypes.string.isRequired,
  eventStatus: PropTypes.string.isRequired,
  setAlertMessage: PropTypes.func.isRequired,
  setButtonChoices: PropTypes.func.isRequired,
  fetchTournamentData: PropTypes.func.isRequired,
};

export default TournamentPoolCreation;
