import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const NumberToAdvance = ({
  numberToAdvance,
  setNumberToAdvance,
  eventId,
  secretKey,
  fetchTournamentData,
  setAlertMessage,
}) => {
  const handleNumberToAdvanceChange = (event) => {
    const enteredNumber = event.target.value;
    setNumberToAdvance(enteredNumber);
    if (enteredNumber && enteredNumber > 0) {
      axios({
        headers: { 'Access-Control-Allow-Origin': '*' },
        method: 'PUT',
        url: `${process.env.ABAMATH_API_URL}/code-championship/event/number-to-advance`,
        params: { eventId, secretKey },
        data: { numberToAdvance: enteredNumber },
      }).then(() => {
        fetchTournamentData();
      }).catch(() => {
        setAlertMessage('Changing the Number To Advance was not successful. If this happens a lot, send us an email!');
      });
    }
  };

  return (
    <>
      <div>
        <div>Number to advance to bracket:</div>
        <input
          type="number"
          value={numberToAdvance}
          onChange={handleNumberToAdvanceChange}
        />
      </div>
    </>
  );
};

NumberToAdvance.propTypes = {
  numberToAdvance: PropTypes.string.isRequired,
  setNumberToAdvance: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
  secretKey: PropTypes.string.isRequired,
  fetchTournamentData: PropTypes.func.isRequired,
  setAlertMessage: PropTypes.func.isRequired,
};

export default NumberToAdvance;
