import React from 'react';
import PropTypes from 'prop-types';
import AdminPlayerRow from './admin-player-row';
import InactivePlayerRow from './inactive-player-row';

const AdminPlayerList = ({
  allPlayers,
  inactivePlayers,
  eventId,
  secretKey,
  fetchTournamentData,
  eventStatus,
  setAlertMessage,
  setButtonChoices,
}) => (
  <>
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Bot Number</th>
        </tr>
      </thead>
      <tbody>
        {allPlayers.map((player) => (
          <AdminPlayerRow
            key={player.id}
            player={player}
            eventId={eventId}
            secretKey={secretKey}
            fetchTournamentData={fetchTournamentData}
            eventStatus={eventStatus}
            setAlertMessage={setAlertMessage}
            setButtonChoices={setButtonChoices}
          />
        ))}
      </tbody>
    </table>
    <h4>Inactive Players</h4>
    <table className="information-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Bot Number</th>
        </tr>
      </thead>
      <tbody>
        {inactivePlayers.map((player) => (
          <InactivePlayerRow
            key={player.id}
            player={player}
            eventId={eventId}
            secretKey={secretKey}
            fetchTournamentData={fetchTournamentData}
            eventStatus={eventStatus}
            setAlertMessage={setAlertMessage}
            setButtonChoices={setButtonChoices}
          />
        ))}
      </tbody>
    </table>
  </>
);

AdminPlayerList.propTypes = {
  allPlayers: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  inactivePlayers: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  eventId: PropTypes.string.isRequired,
  secretKey: PropTypes.string.isRequired,
  fetchTournamentData: PropTypes.func.isRequired,
  setAlertMessage: PropTypes.func.isRequired,
  setButtonChoices: PropTypes.func.isRequired,
  eventStatus: PropTypes.string.isRequired,
};

export default AdminPlayerList;
