import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

const InactivePlayerRow = (props) => {
  const {
    eventId,
    secretKey,
    fetchTournamentData,
    eventStatus,
    setAlertMessage,
    setButtonChoices,
  } = props;

  const makeRequestToReactivatePlayer = () => {
    axios({
      headers: { 'Access-Control-Allow-Origin': '*' },
      method: 'PUT',
      url: `${process.env.ABAMATH_API_URL}/code-championship/registrant/admin`,
      params: { eventId, secretKey },
      data: {
        player: {
          ...props.player,
          isActive: true,
        },
      },
    }).then(() => {
      fetchTournamentData();
    }).catch(() => {
      setAlertMessage('Removing the player was not successful. If this happens a lot, send us an email!');
    });
  };

  const reactivatePlayer = () => {
    if (eventStatus === 'Registration') {
      makeRequestToReactivatePlayer();
    } else if (eventStatus === 'Finished') {
      setAlertMessage('Cannot reactivate a player after the tournament is finished.');
    } else {
      setAlertMessage(`Deactivating a player during ${eventStatus} may have some weird consequences! (If games have already been played, scoring could be altered.)`);
      setButtonChoices([{
        text: 'Undo! Take me back!',
      }, {
        onClick: makeRequestToReactivatePlayer,
        className: 'stripe-button secondary',
        text: 'I am sure! Reactivate the player.',
      }]);
    }
  };

  return (
    <>
      <tr>
        <td>
          {props.player.display_name}
        </td>
        <td>
          {props.player.bot_number || ''}
        </td>
        <td style={{ border: 'none' }}>
          <button className="small-button" type="button" onClick={reactivatePlayer}>
            Reactivate
          </button>
        </td>
      </tr>
    </>
  );
};

InactivePlayerRow.propTypes = {
  player: PropTypes.shape({
    id: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
    bot_number: PropTypes.string,
    suggested_bot_number: PropTypes.string,
  }).isRequired,
  eventId: PropTypes.string.isRequired,
  secretKey: PropTypes.string.isRequired,
  fetchTournamentData: PropTypes.func.isRequired,
  setAlertMessage: PropTypes.func.isRequired,
  setButtonChoices: PropTypes.func.isRequired,
  eventStatus: PropTypes.string.isRequired,
};

export default InactivePlayerRow;
