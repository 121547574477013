import React from 'react';
import PropTypes from 'prop-types';
import FormInput from './formInput';

const RegistrationNameOnlyForm = ({ ...otherProps }) => (
  <>
    <FormInput title="First Name" {...otherProps} />
    <FormInput title="Last Name" {...otherProps} />
  </>
);

RegistrationNameOnlyForm.propTypes = {
  handleChangeFor: PropTypes.func.isRequired,
  allFieldValues: PropTypes.shape({}).isRequired,
  formModel: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  variant: PropTypes.string.isRequired,
};

export default RegistrationNameOnlyForm;
