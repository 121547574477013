import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import PlayerList from './dnd/player-list';

const Container = styled.div`
  margin: 8px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: lightblue;
  transition: background-color 0.2s ease;
`;

const Title = styled.h4`
  padding: 8px;
  transition: background-color ease 0.2s;
  flex-grow: 1;
  user-select: none;
  position: relative;
  &:focus {
    outline: 2px solid pink;
    outline-offset: 2px;
  }
`;

const Column = ({ title, players }) => (
  <Container>
    <Header>
      <Title aria-label={`${title} player list`}>
        {title}
      </Title>
    </Header>
    <PlayerList
      listId={title}
      players={players}
    />
  </Container>
);

Column.propTypes = {
  title: PropTypes.string.isRequired,
  players: PropTypes.arrayOf(PropTypes.shape({})),
};

Column.defaultProps = {
  players: [],
};

export default Column;
