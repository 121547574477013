import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import playerSort from '../../utils/player-sort';
import StandardOutboundButton from './standard-outbound-button';

const Scrimmage = ({ allPlayers }) => {
  const [playerOneProjectId, setPlayerOneProjectId] = useState('');
  const [playerTwoProjectId, setPlayerTwoProjectId] = useState('');
  const [playerOneName, setPlayerOneName] = useState('');
  const [playerTwoName, setPlayerTwoName] = useState('');
  const [championshipArenaAppLink, setChampionshipArenaAppLink] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [previousProjects, setPreviousProjects] = useState([]);

  const updateLinks = () => {
    setErrorMessage('');
    // check for potential errors
    if (playerOneProjectId && (!playerOneProjectId.match(/(\d+)/) || !playerOneProjectId.match(/(\d+)/)[1])) {
      setErrorMessage('Project ID you entered for Player 1 is not a number. Project IDs should be numbers like 142.');
      setChampionshipArenaAppLink('');
    } else if (playerTwoProjectId && (!playerTwoProjectId.match(/(\d+)/) || !playerTwoProjectId.match(/(\d+)/)[1])) {
      setErrorMessage('Project ID you entered for Player 2 is not a number. Project IDs should be numbers like 142.');
      setChampionshipArenaAppLink('');
    } else {
      const matchupQuery = queryString.stringify({
        player1: playerOneProjectId || undefined,
        player2: playerTwoProjectId || undefined,
        player1name: playerOneName || undefined,
        player2name: playerTwoName || undefined,
        fullscreen: 1,
      });

      const matchUpUrl = `${process.env.CODE_CHAMPIONSHIP_APP_URL}/#?${matchupQuery}`;
      setChampionshipArenaAppLink(matchUpUrl);
    }
  };

  useEffect(updateLinks, [playerOneProjectId, playerTwoProjectId, playerOneName, playerTwoName]);

  const clearProjectForm = () => {
    setPlayerOneProjectId('');
    setPlayerTwoProjectId('');
    setPlayerOneName('');
    setPlayerTwoName('');
  };

  const mergeAndUpdateProjects = (listOne, listTwo) => {
    // merge two arrays of project objects into a single array with no duplicate player names
    // keeping list two project when there is a conflict
    // convert project array to object with project names as keys
    const allProjectsObject = [...listOne, ...listTwo]
      // eslint-disable-next-line max-len
      .reduce((allProjectsAggregator, project) => ({ [project.name]: project.projectId.toString(), ...allProjectsAggregator }), {});
    // convert object back to array
    const allProjects = Object.entries(allProjectsObject)
      .map(([key, value]) => ({ name: key, projectId: value }));
    // order projects alphabetically by player name
    setPreviousProjects(allProjects.sort(playerSort));
  };

  const savePreviousProjects = () => {
    const newProjects = [
      {
        name: playerOneName || playerOneProjectId.toString(),
        projectId: playerOneProjectId.toString(),
      },
      {
        name: playerTwoName || playerTwoProjectId.toString(),
        projectId: playerTwoProjectId.toString(),
      },
    ];
    mergeAndUpdateProjects(previousProjects, newProjects);
    clearProjectForm();
  };

  const projectSearchFilter = (searchString) => previousProjects
    .filter((project) => project.name
      && project.name.toLowerCase().includes(searchString.toLowerCase()))
    .sort(playerSort);

  const setProjectOne = (project) => {
    setPlayerOneName(project.name);
    setPlayerOneProjectId(project.projectId);
  };

  const setProjectTwo = (project) => {
    setPlayerTwoName(project.name);
    setPlayerTwoProjectId(project.projectId);
  };

  useEffect(() => {
    const allProjects = allPlayers
      .filter((player) => player.bot_number)
      .map((player) => ({ name: player.display_name, projectId: String(player.bot_number) }));
    mergeAndUpdateProjects(previousProjects, allProjects);
  }, [allPlayers]);

  return (
    <>
      <section className="two-wide-container">
        <div className="two-wide-container">
          <div>
            <h4>Player 1</h4>
            <label htmlFor="playerOneProjectId">
              <div>Bot Number</div>
              <div>
                <input
                  name="playerOneProjectId"
                  onChange={(event) => setPlayerOneProjectId(event.target.value)}
                  placeholder="Player 1 Bot Number"
                  value={playerOneProjectId}
                />
              </div>
            </label>
            <label htmlFor="playerOneName">
              <div>Player Name</div>
              <div>
                <input
                  name="playerOneName"
                  onChange={(event) => setPlayerOneName(event.target.value)}
                  placeholder="Player 1 Name"
                  value={playerOneName}
                />
              </div>
            </label>
            {previousProjects.length > 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingRight: '20px',
                paddingTop: '20px',
              }}
            >
              {projectSearchFilter(playerOneName).map((project) => (
                <div key={JSON.stringify(project)} style={{ display: 'flex', flexGrow: 1, marginTop: '2px' }}>
                  <button
                    type="button"
                    className="simple-button"
                    onClick={() => setProjectOne(project)}
                    style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }}
                  >
                    {project.name}
                    <span style={{ fontSize: '0.6em' }}>{project.projectId}</span>
                  </button>
                </div>
              ))}
            </div>
            )}
          </div>
          <div>
            <h4>Player 2</h4>
            <label htmlFor="playerTwoProjectId">
              <div>Bot Number</div>
              <div>
                <input
                  name="playerTwoProjectId"
                  onChange={(event) => setPlayerTwoProjectId(event.target.value)}
                  placeholder="Player 2 Bot Number"
                  value={playerTwoProjectId}
                />
              </div>
            </label>
            <label htmlFor="playerTwoName">
              <div>Player Name</div>
              <div>
                <input
                  name="playerTwoName"
                  onChange={(event) => setPlayerTwoName(event.target.value)}
                  placeholder="Player 2 Name"
                  value={playerTwoName}
                />
              </div>
            </label>
            {previousProjects.length > 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingRight: '20px',
                paddingTop: '20px',
              }}
            >
              {projectSearchFilter(playerTwoName).map((project) => (
                <div key={JSON.stringify(project)} style={{ display: 'flex', flexGrow: 1, marginTop: '2px' }}>
                  <button
                    type="button"
                    className="simple-button"
                    onClick={() => setProjectTwo(project)}
                    style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }}
                  >
                    {project.name}
                    <span style={{ fontSize: '0.6em' }}>{project.projectId}</span>
                  </button>
                </div>
              ))}
            </div>
            )}
          </div>
        </div>
        <div>
          {errorMessage
            ? <span style={{ color: '#B30300', fontWeight: 'bold' }}>{errorMessage}</span>
            : (playerOneProjectId && playerTwoProjectId && (
            <center>
              {playerOneName && playerTwoName && (
                <>
                  <h4 style={{ paddingBottom: 0 }}>{playerOneName}</h4>
                  <h4 style={{ paddingTop: 0, paddingBottom: 0 }}>vs</h4>
                  <h4 style={{ paddingTop: 0 }}>{playerTwoName}</h4>
                </>
              )}
              <StandardOutboundButton
                href={championshipArenaAppLink}
                onClick={savePreviousProjects}
                className="stripe-button"
              >
                Let&apos;s Go!
              </StandardOutboundButton>
              <button
                type="button"
                className="stripe-button secondary"
                onClick={clearProjectForm}
              >
                Clear
              </button>
            </center>
            ))}
        </div>
      </section>
    </>
  );
};

Scrimmage.propTypes = {
  allPlayers: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
};

export default Scrimmage;
