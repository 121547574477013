// @flow
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Container = styled.a`
  border-radius: 2px;
  border: 1px solid transparent;
  border-color: ${({ isDragging }) => isDragging ? 'white' : 'black'};
  background-color: ${({ isDragging }) => isDragging ? '#26426C' : 'white'};
  color: ${({ isDragging }) => isDragging ? 'white' : 'black'};
  box-shadow: ${({ isDragging }) => isDragging ? `2px 2px 1px #654321` : 'none'};
  box-sizing: border-box;
  padding: 3px;
  min-height: 24px;
  margin-bottom: 3px;
  user-select: none;
  /* anchor overrides */

  &:focus {
    outline: none;
    box-shadow: none;
  }
  /* flexbox */
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  /* flex child */
  flex-grow: 1;
  /*
    Needed to wrap text in ie11
    https://stackoverflow.com/questions/35111090/why-ie11-doesnt-wrap-the-text-in-flexbox
  */
  flex-basis: 100%;
  /* flex parent */
  display: flex;
  flex-direction: column;
`;

// Previously this extended React.Component
// That was a good thing, because using React.PureComponent can hide
// issues with the selectors. However, moving it over does can considerable
// performance improvements when reordering big lists (400ms => 200ms)
// Need to be super sure we are not relying on PureComponent here for
// things we should be doing in the selector as we do not know if consumers
// will be using PureComponent
const PlayerItem = ({
  player, isDragging, provided, isWinner, isTie,
}) => {
  const isDraggable = provided && provided.innerRef && provided.draggableProps && provided.dragHandleProps;

  const dragAndDropProps = isDraggable ? {
    isDragging,
    ref: provided.innerRef,
    ...provided.draggableProps,
    ...provided.dragHandleProps
  } : {};

  const displayName = player ? player.display_name : '-- Bye --';

  return (
    <Container aria-label={displayName} {...dragAndDropProps}>
      <Content>
        {displayName}
        <span style={{ fontSize: '0.6em' }}>
          {isTie && ' 🏆'}
          {isWinner && ' 🏆 🏆'}
        </span>
      </Content>
      {player && <span style={{ fontSize: '0.6em' }}>{player.bot_number}</span> }
    </Container>
  );
};

PlayerItem.propTypes = {
  player: PropTypes.shape({
    display_name: PropTypes.string.isRequired,
    image_url: PropTypes.string,
    bot_number: PropTypes.string,
  }).isRequired,
  provided: PropTypes.shape({
    innerRef: PropTypes.func.isRequired,
    draggableProps: PropTypes.shape({}).isRequired,
    dragHandleProps: PropTypes.shape({}).isRequired,
  }),
  isDragging: PropTypes.bool,
  isWinner: PropTypes.bool,
  isTie: PropTypes.bool,
};

PlayerItem.defaultProps = {
  isDragging: null,
  provided: null,
  isWinner: false,
  isTie: false,
};

export default React.memo(PlayerItem);