import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import PlayerItem from './dnd/player-item';

const MatchUp = ({
  matchUp,
  eventId,
  secretKey,
  isAdmin,
  isBracket,
  isPlayerQualifyingMatch,
  isOpponentQualifyingMatch,
  fetchTournamentData,
  index,
  eventStatus,
  setAlertMessage,
  setButtonChoices,
  setCurrentView,
}) => {
  const type = isBracket ? 'Bracket' : 'Pool';

  const setRecommendationButtons = () => {
    if (eventStatus === 'Registration') {
      setButtonChoices([{
        onClick: () => setCurrentView('Registration'),
        text: 'Go to Registration to Finish Setup',
      }]);
    } else if (eventStatus === 'Pool Play') {
      setButtonChoices([{
        onClick: () => setCurrentView('Pool Play'),
        text: 'Go to Pool Play to Finish Pool Play',
      }]);
    } else if (eventStatus === 'Bracket Play') {
      setButtonChoices([{
        onClick: () => setCurrentView('Bracket Play'),
        text: 'Go to Bracket Play to Finish Tournament',
      }]);
    }
  };

  const setWinner = (winnerId) => {
    if (eventStatus === `${type} Play`) {
      setAlertMessage(false);
      axios({
        headers: { 'Access-Control-Allow-Origin': '*' },
        method: 'POST',
        url: `${process.env.ABAMATH_API_URL}/code-championship/game`,
        params: { eventId, secretKey },
        data: {
          left: matchUp.player.id,
          right: matchUp.opponent.id,
          winner: winnerId,
          type,
        },
      }).then(() => {
        fetchTournamentData();
      }).catch(() => {
        setAlertMessage('Game POST was not successfully saved to database.');
      });
    } else {
      setAlertMessage(`Cannot change winner of ${type} Play game during ${eventStatus}`);
      setRecommendationButtons();
    }
  };

  const displayPlayerQualifyingMatch = matchUp.playerQualifyingMatch
      && matchUp.playerQualifyingMatch.player && matchUp.playerQualifyingMatch.opponent;
  const displayOpponentQualifyingMatch = matchUp.opponentQualifyingMatch
      && matchUp.opponentQualifyingMatch.opponent && matchUp.opponentQualifyingMatch.opponent;
  const isQualifyingMatch = isPlayerQualifyingMatch || isOpponentQualifyingMatch;
  const bracketStyling = isBracket ? { display: 'grid', gridTemplateColumns: 'auto 10px 150px 5px 5px', padding: '10px 0px' } : {};
  const leftBottomCornerBottom = isOpponentQualifyingMatch ? '3px solid black' : '1px solid black';
  const leftBottomCornerStyling = isBracket ? {
    height: '20px', borderLeft: '3px solid black', borderBottom: leftBottomCornerBottom, marginTop: 'auto',
  } : {};
  const leftTopCornerTop = isPlayerQualifyingMatch ? '3px solid black' : '1px solid black';
  const leftTopCornerStyling = isBracket ? {
    height: '20px', borderLeft: '3px solid black', borderTop: leftTopCornerTop,
  } : {};
  const rightBottomCornerStyling = isBracket && isOpponentQualifyingMatch ? {
    borderRight: '3px solid black', borderBottom: '3px solid black', width: '100%', flexGrow: '1',
  } : {};
  const rightTopCornerStyling = isBracket && isPlayerQualifyingMatch ? {
    borderRight: '3px solid black', borderTop: '3px solid black', width: '100%', flexGrow: '1',
  } : {};
  const matchupQuery = queryString.stringify({
    player1: matchUp && matchUp.player && matchUp.player.bot_number,
    player2: matchUp && matchUp.opponent && matchUp.opponent.bot_number,
    player1name: matchUp && matchUp.player && matchUp.player.display_name,
    player2name: matchUp && matchUp.opponent && matchUp.opponent.display_name,
    fullscreen: 1,
  });
  const matchUpUrl = `${process.env.CODE_CHAMPIONSHIP_APP_URL}/#?${matchupQuery}`;
  const isComplete = matchUp?.winner?.display_name !== ' ';
  const isPlayerWin = isComplete && matchUp.player.id === matchUp.winner.id;
  const isOpponentWin = isComplete && matchUp.opponent.id === matchUp.winner.id;
  const isTie = isComplete && matchUp.winner.display_name === 'Tie Game' && !matchUp.winner.id;
  const isPlayable = matchUp?.player?.bot_number && matchUp?.opponent?.bot_number;

  const askWhoWon = () => {
    setAlertMessage('Who won the matchup?');
    const winnerChoices = [{
      className: 'stripe-button secondary',
      onClick: () => setWinner(matchUp.player.id),
      text: matchUp.player.display_name,
    }, {
      className: 'stripe-button secondary',
      onClick: () => setWinner(matchUp.opponent.id),
      text: matchUp.opponent.display_name,
    }];
    const winnerChoicesAndTie = [
      ...winnerChoices,
      {
        className: 'stripe-button secondary',
        onClick: () => setWinner(null),
        text: 'Tie',
      },
    ];
    if (isBracket) {
      setButtonChoices(winnerChoices);
    } else {
      setButtonChoices(winnerChoicesAndTie);
    }
  };

  const openMatchUpTab = () => {
    const appWindow = window.open(matchUpUrl, '_blank');
    appWindow.focus();
    if (isAdmin && eventStatus === `${type} Play`) {
      askWhoWon();
    }
  };

  const handlePlayMatchup = () => {
    if (isAdmin && eventStatus !== `${type} Play`) {
      setAlertMessage(`Are you sure you want to play a ${type} Play game during ${eventStatus}? You cannot change the game results (select a winner) at this time.`);
      setButtonChoices([{
        className: 'stripe-button',
        onClick: openMatchUpTab,
        text: 'Yes, I am sure.',
      }, {
        className: 'stripe-button secondary',
        onClick: () => {},
        text: 'Oops! Never mind.',
      }]);
    } else {
      openMatchUpTab();
    }
  };

  return (
    <div style={bracketStyling}>
      <div>
        {displayPlayerQualifyingMatch && (
          <MatchUp
            matchUp={matchUp.playerQualifyingMatch}
            fetchTournamentData={fetchTournamentData}
            eventId={eventId}
            secretKey={secretKey}
            isAdmin={isAdmin}
            isBracket
            isPlayerQualifyingMatch
            eventStatus={eventStatus}
            setAlertMessage={setAlertMessage}
            setButtonChoices={setButtonChoices}
            setCurrentView={setCurrentView}
          />
        )}
      </div>
      {displayPlayerQualifyingMatch ? (
        <div style={{ marginTop: 'auto' }}>
          <div style={leftBottomCornerStyling} />
          <div style={{ height: '15px' }} />
        </div>
      ) : <div /> }
      <div style={{ marginTop: 'auto' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {!isBracket && (
            <span style={{ marginTop: 'auto', marginRight: '10px', alignSelf: 'start' }}>
              {`Game ${index} | Pool ${matchUp.player.pool_id}`}
            </span>
          )}
          {isPlayable && (
            <button
              type="button"
              onClick={handlePlayMatchup}
              className={`small-button${matchUp.winner.display_name !== 'Tie Game' && !matchUp.winner.id ? ' active' : ''} play-game-button`}
            >
              Play →
            </button>
          )}
        </div>
        <PlayerItem
          player={matchUp.player}
          isWinner={isPlayerWin}
          isTie={isTie}
        />
      </div>
      {isQualifyingMatch ? <div style={{ ...leftBottomCornerStyling, marginTop: 'auto' }} /> : <div />}
      {isBracket && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ height: '25px' }} />
          <div style={rightBottomCornerStyling} />
        </div>
      )}
      <div>
        {displayOpponentQualifyingMatch && (
          <MatchUp
            matchUp={matchUp.opponentQualifyingMatch}
            fetchTournamentData={fetchTournamentData}
            eventId={eventId}
            secretKey={secretKey}
            isAdmin={isAdmin}
            isBracket
            isOpponentQualifyingMatch
            eventStatus={eventStatus}
            setAlertMessage={setAlertMessage}
            setButtonChoices={setButtonChoices}
            setCurrentView={setCurrentView}
          />
        )}
      </div>
      {displayOpponentQualifyingMatch ? (
        <div>
          <div style={{ height: '15px' }} />
          <div style={leftTopCornerStyling} />
        </div>
      ) : <div /> }
      <div>
        <PlayerItem
          player={matchUp.opponent}
          isWinner={isOpponentWin}
          isTie={isTie}
        />
        {isAdmin && eventStatus === `${type} Play` && (
          <div>
            {matchUp.player && matchUp.opponent && matchUp.player.display_name !== ' ' && matchUp.opponent.display_name !== ' ' && (
            <>
              <button type="button" className={`small-button${isPlayerWin ? ' active' : ''}`} onClick={() => setWinner(matchUp.player.id)}>{matchUp.player.display_name}</button>
              <button type="button" className={`small-button${isOpponentWin ? ' active' : ''}`} onClick={() => setWinner(matchUp.opponent.id)}>{matchUp.opponent.display_name}</button>
              {!isBracket && <button type="button" className={`small-button${isTie ? ' active' : ''}`} onClick={() => setWinner(null)}>Tie</button>}
            </>
            )}
          </div>
        )}
      </div>
      {isQualifyingMatch ? <div style={leftTopCornerStyling} /> : <div />}
      {isBracket && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={rightTopCornerStyling} />
          <div style={{ height: '25px' }} />
        </div>
      )}
    </div>
  );
};

MatchUp.propTypes = {
  matchUp: PropTypes.shape({
    playerQualifyingMatch: PropTypes.shape({
      opponent: PropTypes.shape({
        display_name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }),
      player: PropTypes.shape({
        display_name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        bot_number: PropTypes.string,
      }).isRequired,
    }),
    opponentQualifyingMatch: PropTypes.shape({
      opponent: PropTypes.shape({}),
    }),
    player: PropTypes.shape({
      id: PropTypes.string,
      bot_number: PropTypes.string,
      display_name: PropTypes.string.isRequired,
      pool_id: PropTypes.string,
    }).isRequired,
    opponent: PropTypes.shape({
      id: PropTypes.string,
      bot_number: PropTypes.string,
      display_name: PropTypes.string.isRequired,
      pool_id: PropTypes.string,
    }).isRequired,
    winner: PropTypes.shape({
      display_name: PropTypes.string.isRequired,
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
  eventId: PropTypes.string.isRequired,
  secretKey: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool,
  isBracket: PropTypes.bool,
  isPlayerQualifyingMatch: PropTypes.bool,
  isOpponentQualifyingMatch: PropTypes.bool,
  fetchTournamentData: PropTypes.func.isRequired,
  setAlertMessage: PropTypes.func.isRequired,
  setButtonChoices: PropTypes.func.isRequired,
  setCurrentView: PropTypes.func.isRequired,
  index: PropTypes.string,
  eventStatus: PropTypes.string.isRequired,
};

MatchUp.defaultProps = {
  isAdmin: false,
  isBracket: false,
  isPlayerQualifyingMatch: false,
  isOpponentQualifyingMatch: false,
  index: null,
};

export default MatchUp;
