import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { Link } from 'gatsby';
import queryString from 'query-string';
import MatchUp from './match-up';
import SeedStandings from './seed-standings';
import TournamentStatusButton from './tournament-status-button';

import columnToPlayerList from '../../utils/columns-to-player-list';
import AdminPlayerList from './admin-player-list';
import TournamentPlayersForm from './tournament-players-form';
import TournamentPoolCreation from './tournament-pool-creation';
import NumberToAdvance from './number-to-advance';
import Modal from './modal';
import CelebrationButton from './celebration-button';
import AdminChallengeSelection from './admin-challenge-selection';
import useTournamentAuth from '../hooks/use-tournament-auth';
import Create from './create';
import Tab from './tab';
import TabPanel from './tab-panel';
import Scrimmage from './scrimmage';

const Board = () => {
  const [{
    eventId, secretKey, playerSecretKey, tournamentAccessList,
  }] = useCookies();
  const { signOut } = useTournamentAuth();
  const isAdmin = secretKey && secretKey !== 'undefined';
  const existingTournaments = Object.values(tournamentAccessList || {}).length > 0;
  const adminTournamentSearch = queryString.stringify({ eventId, secretKey });
  const tournamentUrl = `/tournament/home/?${adminTournamentSearch}`;
  const playerTournamentSearch = queryString.stringify({ eventId, playerSecretKey });
  const playerTournamentUrl = `/tournament/home/?${playerTournamentSearch}`;
  const [columns, setColumns] = useState({});
  const [ordered, setOrdered] = useState([]);
  const [matchUps, setMatchUps] = useState([]);
  const [shuffledMatchUps, setShuffledMatchUps] = useState([]);
  const [inactivePlayers, setInactivePlayers] = useState([]);
  const [seeds, setSeeds] = useState([]);
  const [bracket, setBracket] = useState(null);
  const [eventTitle, setEventTitle] = useState('');
  const [tabToDisplay, setTabToDisplay] = useState(null);
  const [eventStatus, setEventStatus] = useState(null);
  const [challengeId, setChallengeId] = useState(null);
  const [currentView, setCurrentView] = useState(null);
  const [numberToAdvance, setNumberToAdvance] = useState('0');
  const [shuffle, setShuffle] = useState(true);
  const [showEverything, setShowEverything] = useState(false);
  const [showGoBack, setShowGoBack] = useState(true);
  const [alertMessage, setAlertMessage] = useState('');
  const [buttonChoices, setButtonChoices] = useState([]);
  const [playerId, setPlayerId] = useState(null);
  const [playerName, setPlayerName] = useState(null);
  let isSubscribed = true;

  const fetchTournamentData = () => {
    axios({
      headers: { 'Access-Control-Allow-Origin': '*' },
      method: 'GET',
      url: `${process.env.ABAMATH_API_URL}/code-championship/registrant/event`,
      params: { eventId, secretKey, playerSecretKey },
    }).then((response) => {
      if (isSubscribed) {
        setColumns(response.data.pools);
        setOrdered(Object.keys(response.data.pools));
        setMatchUps(response.data.matchUps);
        setShuffledMatchUps(response.data.shuffledMatchUps);
        setInactivePlayers(response.data.inactivePlayers);
        setSeeds(response.data.seeds);
        setBracket(response.data.bracket);
        setEventTitle(response.data.title);
        setEventStatus(response.data.status);
        setChallengeId(response.data.challenge_id);
        setNumberToAdvance(response.data.number_to_advance);
        setShowEverything(true);
      }
    }).catch(() => {
      setAlertMessage('We were unable to get your information from the database. You may need to refresh the page.');
    });
  };

  useEffect(() => {
    fetchTournamentData();
    const fetchInterval = window.setInterval(fetchTournamentData, 10000);
    return () => {
      window.clearInterval(fetchInterval);
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    if (eventStatus && !currentView) setCurrentView(eventStatus);
  }, [eventStatus]);

  const roundRobinMatchUps = shuffle ? shuffledMatchUps : matchUps;
  const allPlayers = columnToPlayerList(columns);
  const playersWithoutBots = allPlayers.filter((player) => !player.bot_number);

  useEffect(() => {
    setPlayerName(allPlayers.find((player) => player.id === playerId)?.display_name);
  }, [playerId]);

  let registrationError = '';
  if (playersWithoutBots.length > 0) {
    registrationError = 'All players must have bot numbers before advancing to Pool Play';
  } else if (allPlayers.length < 2) {
    registrationError = 'There must be at least two players in this tournament';
  } else if (columns['Not In Pool Yet'] && columns['Not In Pool Yet'].length > 0) {
    registrationError = 'There cannot be any players without pools. Remove those players from the tournament, or add them to a pool.';
  }

  const standardProps = {
    eventId,
    eventStatus,
    fetchTournamentData,
    registrationError,
    secretKey,
    isAdmin,
    setAlertMessage,
    setButtonChoices,
    setCurrentView,
  };

  return (
    <>
      <Modal
        showModal={!!alertMessage}
        setShowModal={setAlertMessage}
        buttonChoices={buttonChoices}
        setButtonChoices={setButtonChoices}
      >
        <>{alertMessage}</>
      </Modal>
      { showEverything && eventId && (secretKey || playerSecretKey) && (
        <>
          <h1>{eventTitle}</h1>
          <div className="stripe-hr thin" style={{ marginBottom: '20px' }} />
          {isAdmin && (
            <p>
              Learn more about how to run a tournament:
              {' '}
              <Link to="/tournament/instructions/">
                Instructions
              </Link>
            </p>
          )}
          { existingTournaments && (
            <Tab
              title="My Tournaments"
              tabToDisplay={tabToDisplay}
              setTabToDisplay={setTabToDisplay}
            />
          )}
          { isAdmin && (
            <Tab
              title="Admin Link"
              tabToDisplay={tabToDisplay}
              setTabToDisplay={setTabToDisplay}
            />
          )}
          <Tab
            title="Player Link"
            tabToDisplay={tabToDisplay}
            setTabToDisplay={setTabToDisplay}
          />
          <Tab
            title="Standings"
            tabToDisplay={tabToDisplay}
            setTabToDisplay={setTabToDisplay}
          />
          <Tab
            title="Scrimmage"
            tabToDisplay={tabToDisplay}
            setTabToDisplay={setTabToDisplay}
          />
          {existingTournaments && (
            <TabPanel
              title="My Tournaments"
              tabToDisplay={tabToDisplay}
            >
              {Object.values(tournamentAccessList || {})?.map((tournamentDetails) => (
                <div key={tournamentDetails.eventId}>
                  <a href={tournamentDetails.tournamentUrl} style={{ fontSize: '20px' }}>
                    {tournamentDetails.title}
                  </a>
                  {' '}
                  <button
                    type="button"
                    className="small-button"
                    onClick={() => signOut(tournamentDetails.eventId)}
                  >
                    Log Out
                  </button>
                </div>
              ))}
            </TabPanel>
          )}
          { isAdmin && (
            <TabPanel
              title="Admin Link"
              tabToDisplay={tabToDisplay}
            >
              This link is a secret, so only share it with other tournament administrators
              <br />
              <a href={tournamentUrl}>
                {`${process.env.CURRENT_BASE_URL}${tournamentUrl}`}
              </a>
            </TabPanel>
          )}
          <TabPanel
            title="Player Link"
            tabToDisplay={tabToDisplay}
          >
            <p style={{ display: 'inline-block', margin: '5px 10px' }}>
              This link is a secret, so only share it with players in this tournament
              <br />
              <Link to={playerTournamentUrl}>
                {`${process.env.CURRENT_BASE_URL}${playerTournamentUrl}`}
              </Link>
            </p>
          </TabPanel>
          <TabPanel
            title="Standings"
            tabToDisplay={tabToDisplay}
          >
            {isAdmin && (
              <>
                <NumberToAdvance
                  numberToAdvance={numberToAdvance.toString()}
                  setNumberToAdvance={setNumberToAdvance}
                  {...standardProps}
                />
              </>
            )}
            <SeedStandings seeds={seeds} />
          </TabPanel>
          <TabPanel
            title="Scrimmage"
            tabToDisplay={tabToDisplay}
          >
            <Scrimmage allPlayers={allPlayers} />
          </TabPanel>
          <br />
          <div className="three-wide-container">
            <button type="button" onClick={() => setCurrentView('Registration')} className={`stripe-button secondary${currentView === 'Registration' ? ' selected' : ''}${eventStatus !== 'Registration' ? ' inactive' : ''}`}>Setup</button>
            <button type="button" onClick={() => setCurrentView('Pool Play')} className={`stripe-button secondary${currentView === 'Pool Play' ? ' selected' : ''}${eventStatus !== 'Pool Play' ? ' inactive' : ''}`}>Pool Play</button>
            <button type="button" onClick={() => setCurrentView('Bracket Play')} className={`stripe-button secondary${currentView === 'Bracket Play' ? ' selected' : ''}${eventStatus !== 'Bracket Play' ? ' inactive' : ''}`}>Bracket Play</button>
          </div>
          <br />
          {currentView === 'Registration' && (
            <>
              {/* eslint-disable-next-line no-nested-ternary */}
              {isAdmin ? (
                <>
                  <h3>Select Tournament Challenge</h3>
                  <AdminChallengeSelection
                    playerSecretKey={playerSecretKey}
                    challengeId={challengeId}
                    {...standardProps}
                  />
                  <h3>Players</h3>
                  <TournamentPlayersForm
                    eventId={eventId}
                    secretKey={secretKey}
                    onSuccess={fetchTournamentData}
                  />
                  <AdminPlayerList
                    allPlayers={allPlayers}
                    inactivePlayers={inactivePlayers}
                    {...standardProps}
                  />
                  <h3>Pool Creation</h3>
                  <TournamentPoolCreation
                    columns={columns}
                    ordered={ordered}
                    {...standardProps}
                  />
                  <TournamentStatusButton
                    thisStatus="Registration"
                    newStatus="Pool Play"
                    {...standardProps}
                  />
                </>
              // eslint-disable-next-line no-nested-ternary
              ) : (challengeId ? (playerId ? (
                <>
                  <h2>{`Welcome ${playerName}!`}</h2>
                  {showGoBack && (
                    <>
                      <p>
                        When you are ready, click the button to create a new bot. If you are not
                        {' '}
                        {playerName}
                        {' '}
                        click the button to go back.
                      </p>
                      <button
                        type="button"
                        className="stripe-button secondary"
                        onClick={() => setPlayerId('')}
                      >
                        ← Go Back!
                      </button>
                    </>
                  )}
                  <Create
                    currentChallenge={{ id: challengeId }}
                    playerId={playerId}
                    eventId={eventId}
                    playerSecretKey={playerSecretKey}
                    onCreate={() => setShowGoBack(false)}
                  />
                </>
              ) : (
                <>
                  <h2>Click your name!</h2>
                  <p>
                    If you don&apos;t see your name here,
                    ask the tournament administrator for help.
                  </p>
                  <ul className="player-name-button-list">
                    {allPlayers.map((player) => (
                      <li key={player.id}>
                        <button type="button" className="simple-button" onClick={() => setPlayerId(player.id)}>
                          {player.display_name}
                        </button>
                      </li>
                    ))}
                  </ul>
                </>
              )) : ('tournament admin has not selected a challenge yet'))}
            </>
          )}
          {currentView === 'Pool Play' && (
            <>
              <h3>Round Robin Match Ups</h3>
              <button
                type="button"
                className={`simple-button${shuffle ? ' active' : ''}`}
                onClick={() => setShuffle(true)}
              >
                Sort by Schedule Order
              </button>
              <button
                type="button"
                className={`simple-button${shuffle ? '' : ' active'}`}
                onClick={() => setShuffle(false)}
              >
                Sort by Pool
              </button>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr' }}>
                {roundRobinMatchUps.map((matchUp, index) => (
                  <div
                    key={`${matchUp.player.id}-${matchUp.opponent.id}`}
                    style={{ padding: '10px' }}
                  >
                    <MatchUp
                      matchUp={matchUp}
                      index={String(index + 1)}
                      {...standardProps}
                    />
                  </div>
                ))}
              </div>
              <TournamentStatusButton
                thisStatus="Pool Play"
                newStatus="Bracket Play"
                {...standardProps}
              />
            </>
          )}
          {currentView === 'Bracket Play' && (
            <>
              <h3>Bracket</h3>
              <div style={{ marginLeft: '-80px' }}>
                {bracket?.player && bracket?.opponent && (
                  <MatchUp
                    matchUp={bracket}
                    isBracket
                    {...standardProps}
                  />
                )}
              </div>
              {bracket?.winner?.id && (
                <TournamentStatusButton
                  thisStatus="Bracket Play"
                  newStatus="Finished"
                  {...standardProps}
                />
              )}
            </>
          )}
          {currentView === 'Finished' && (
            <>
              <center style={{ padding: '50px 0px' }}>
                <h3>
                  Congratulations
                  {/* eslint-disable-next-line */}
                  {bracket?.winner?.id && ` ${bracket?.winner?.display_name}`}
                  !
                </h3>
                <p>Congratulations to all those who participated!</p>
                <p>We hope to see you again soon!</p>
                <CelebrationButton />
              </center>
            </>
          )}
          <div>{`Current Tournament Status: ${currentView === 'Registration' ? 'Setup' : eventStatus}`}</div>
        </>
      )}
    </>
  );
};

export default Board;
