import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import StripeContainer from './stripe-container';
import FormContainer from './form-container';
import RegistrationNameOnlyForm from './registration-name-only-form';

const TournamentPlayersForm = ({ eventId, secretKey, onSuccess }) => (
  <StaticQuery
    query={graphql`{
      allNameOnlyRegistrationFormFields(filter: {id: {ne: "dummy"}}) {
        edges {
          node {
            fieldName
            type
            required
          }
        }
      }
    }`}
    render={(data) => (
      <StripeContainer>
        <FormContainer
          formModel={data.allNameOnlyRegistrationFormFields.edges.map((edge) => edge.node)}
          postEndpoint="/registrant/admin"
          supportEmail="support@codechampionship.com"
          formComponent={RegistrationNameOnlyForm}
          eventId={eventId}
          secretKey={secretKey}
          variant="compact"
          onSuccess={onSuccess}
        />
      </StripeContainer>
    )}
  />
);

TournamentPlayersForm.propTypes = {
  eventId: PropTypes.string.isRequired,
  secretKey: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
};

TournamentPlayersForm.defaultProps = {
  onSuccess: () => {},
};

export default TournamentPlayersForm;
