import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

const TournamentStatusButton = ({
  eventId,
  secretKey,
  fetchTournamentData,
  newStatus,
  setCurrentView,
  registrationError,
  setAlertMessage,
  setButtonChoices,
  eventStatus,
  thisStatus,
}) => {
  const setTournamentStatus = () => {
    setCurrentView(newStatus);
    axios({
      headers: { 'Access-Control-Allow-Origin': '*' },
      method: 'PUT',
      url: `${process.env.ABAMATH_API_URL}/code-championship/event/status`,
      params: { eventId, secretKey },
      data: { status: newStatus },
    }).then(() => {
      fetchTournamentData();
    }).catch(() => {
      setAlertMessage('Event Status Update was not successful');
    });
  };

  const confirmTournamentChange = () => {
    setAlertMessage(`Are you sure? Once you advance to ${newStatus}, you can't come back!`);
    setButtonChoices([{
      className: 'stripe-button secondary',
      text: 'Take me back!',
    }, {
      onClick: setTournamentStatus,
      text: `I am sure! Take me to ${newStatus}`,
    }]);
  };

  const alertAdvancementError = () => {
    setAlertMessage(registrationError);
    setButtonChoices([{
      onClick: () => setCurrentView('Registration'),
      text: 'Go to Registration to update bot Numbers',
    }]);
  };

  return (
    <>
      {registrationError && <p style={{ color: '#B30000' }}>{registrationError}</p>}
      {thisStatus === eventStatus && (
      <button
        type="button"
        onClick={registrationError ? alertAdvancementError : confirmTournamentChange}
        className={`stripe-button${registrationError && ' error'}`}
      >
        {`Advance to ${newStatus}`}
      </button>
      )}
    </>
  );
};

TournamentStatusButton.propTypes = {
  eventId: PropTypes.string.isRequired,
  secretKey: PropTypes.string.isRequired,
  fetchTournamentData: PropTypes.func.isRequired,
  setCurrentView: PropTypes.func.isRequired,
  setAlertMessage: PropTypes.func.isRequired,
  setButtonChoices: PropTypes.func.isRequired,
  newStatus: PropTypes.string.isRequired,
  eventStatus: PropTypes.string.isRequired,
  thisStatus: PropTypes.string.isRequired,
  registrationError: PropTypes.string.isRequired,
};

export default TournamentStatusButton;
