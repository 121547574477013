import React from 'react';
import PropTypes from 'prop-types';

const TabPanel = ({ children, title, tabToDisplay }) => {
  const show = tabToDisplay === title;
  return (show && (
    <div style={{ padding: '15px', border: '#112464 solid' }}>
      {children}
    </div>
  ));
};

TabPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string.isRequired,
  tabToDisplay: PropTypes.string,
};

TabPanel.defaultProps = {
  tabToDisplay: null,
};

export default TabPanel;
