import playerSort from "../../../utils/player-sort";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default reorder;

export const reorderPlayerMap = ({
  playerMap, source, destination,
}) => {
  const current = [...playerMap[source.droppableId]];
  const next = [...playerMap[destination.droppableId]];
  const target = current[source.index];

  // moving to same list
  // should be handled in level above (no action required)
  if (source.droppableId === destination.droppableId) return { playerMap };

  // moving to different list

  // remove from original
  current.splice(source.index, 1);
  // insert into next
  next.splice(destination.index, 0, target);

  const result = {
    ...playerMap,
    [source.droppableId]: current.sort(playerSort),
    [destination.droppableId]: next.sort(playerSort),
  };

  return {
    playerMap: result,
  };
};
