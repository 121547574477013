import React from 'react';
import PropTypes from 'prop-types';

const Tab = ({ title, tabToDisplay, setTabToDisplay }) => {
  const show = tabToDisplay === title;
  return (
    <button
      type="button"
      onClick={() => (show ? setTabToDisplay(null) : setTabToDisplay(title))}
      className={`toggle-button${show ? ' selected' : ''}`}
    >
      {title}
    </button>
  );
};

Tab.propTypes = {
  title: PropTypes.string.isRequired,
  tabToDisplay: PropTypes.string,
  setTabToDisplay: PropTypes.func.isRequired,
};

Tab.defaultProps = {
  tabToDisplay: null,
};

export default Tab;
