import React, { useEffect, useState } from 'react';
import confetti from 'canvas-confetti';

const fireConfetti = () => {
  if (typeof window !== 'undefined') {
    const colors = ['#011353', '#ffffff'];
    confetti({
      particleCount: 100,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
      colors,
    });
    confetti({
      particleCount: 100,
      angle: 120,
      spread: 55,
      origin: { x: 1 },
      colors,
    });
  }
};

const CelebrationButton = () => {
  const [milliseconds, setMilliseconds] = useState(0);
  const triggerConfetti = () => setMilliseconds(5000);
  useEffect(() => {
    const interval = setInterval(() => {
      fireConfetti();
      setMilliseconds(milliseconds - 300);
    }, 300);
    if (milliseconds <= 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [milliseconds]);
  // eslint-disable-next-line
  return (
    <button
      type="button"
      onClick={triggerConfetti}
      className="stripe-button"
    >
      Celebrate!
    </button>
  );
};

export default CelebrationButton;
