import playerSort from './player-sort';

// this should be replaced by .flat at some point
// we are using this to be compatible with older browsers
const flatten = (array, result = []) => {
  for (let i = 0; i < array.length; i += 1) {
    const value = array[i];
    if (Array.isArray(value)) {
      flatten(value, result);
    } else {
      result.push(value);
    }
  }
  return result;
};

const columnToPlayerList = (columns) => flatten(Object.values(columns || {}))
  .sort(playerSort);

export default columnToPlayerList;
