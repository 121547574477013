import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import Layout from '../../components/layout';
import Board from '../../components/board';
import useTournamentAuth from '../../hooks/use-tournament-auth';

const Home = ({ location }) => {
  const { isChecking, isSignedIn, signIn } = useTournamentAuth();

  useEffect(() => {
    signIn(queryString.parse(location.search));
  }, [location.search]);

  return (
    <Layout>
      {isChecking ? (<div>Loading...</div>) : (
        <div>
          {isSignedIn ? (
            <Board />
          ) : (
            <>
              <p>
                This page is for educators running their own tournaments.
                <br />
                <Link to="/coders/">Click here</Link>
                {' '}
                if you are a coder looking to join a tournament.
              </p>
              <p>
                If you are running a Tournament Event,
                you should have a secret Admin Link to log in.
              </p>
              <p>
                If you need help,
                please reach out to us at support@codechampionship.com
              </p>
            </>
          )}
        </div>
      )}
    </Layout>
  );
};

Home.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default Home;
